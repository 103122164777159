
const SET_RECOVERY_MAIL = "SET_RECOVERY_MAIL"

export const setRecoveryMailAction = email => ({
  type: SET_RECOVERY_MAIL,
  payload: { email }
})

const initialState = {
  email: ""
}

export default function uiForgotPasswordReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECOVERY_MAIL:
      return {
        ...state,
        email: action.payload.email,
      }
    default:
      return state
  }
}