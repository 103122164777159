
const SET_RESET_TOKEN = 'SET_RESET_TOKEN'
const SET_RESET_PASSWORD = 'SET_RESET_PASSWORD'

export const setResetTokenAction = token => ({
  type: SET_RESET_TOKEN,
  payload: {
    token,
  }
})

export const setResetPasswordAction = password => ({
  type: SET_RESET_PASSWORD,
  payload: {
    password,
  }
})

const initialState = {
  token: "",
  password: "",
}

export default function uiPasswordResetReducer( state = initialState, action) {
  switch( action.type) {
    case SET_RESET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      }
    case SET_RESET_PASSWORD:
      return {
        ...state,
        password: action.payload.password,
      }
    default:
      return state
  }
}