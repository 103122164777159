import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Loader from 'react-loaders';

const mapStateToProps = store => ({
  account: store.entity.account
})

class LoggedInRoute extends Component {
  render() {
    const { isAuthenticated, accessToken } = this.props.account
    
    if (isAuthenticated)
      return <Route path={this.props.path} component={this.props.component} />

    return <Route path={this.props.path} render={ (props) =>
      undefined !== accessToken ?
        <div className="loader-container">
          <div className="loader-container-inner">
              <div className="text-center">
                  <Loader type="line-scale-party"/>
              </div>
              <h6 className="mt-3">
                  Loading...
              </h6>
          </div>
        </div>
      :
        <Redirect to="/pages/login-boxed"/>
    }/>
  }
}

export default connect(
  mapStateToProps,
)(LoggedInRoute);